import React from 'react';
import { View, Text, ButtonLink, HeaderText, Link } from '../components/common';
import Layout from '../components/layout';
import Content, { TINY, SMALL, MEDIUM } from '../components/content';
import Checkmark from '../components/svg/Checkmark';
import { colors } from '../style';
import SubscribeForm from '../components/SubscribeForm';

function QA({ title, children }) {
  return (
    <View style={{ width: 400, [MEDIUM]: { width: 'auto' } }}>
      <Text
        style={{
          fontSize: 17,
          fontWeight: 600,
          marginTop: 50,
          marginBottom: 20
        }}
      >
        {title}
      </Text>
      <Text>{children}</Text>
    </View>
  );
}

const styles = {
  card: {
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 20,
    position: 'relative',
    boxShadow: '0 8px 40px rgba(0, 0, 0, .1)',
    [MEDIUM]: {
      boxShadow: 'none',
      backgroundColor: 'transparent'
    }
  },
  title: {
    marginTop: 10,
    marginBottom: 15,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 600
  },
  price: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 700
  },
  priceFooter: {
    textAlign: 'center',
    marginTop: -5
  },
  description: {
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: 1.25,
    marginTop: 30,
    marginBottom: 15
  }
};

function Feature({ faded, children }) {
  return (
    <View style={{ flexDirection: 'row', marginTop: 10 }}>
      <View
        style={{
          width: 15,
          marginTop: 5,
          marginRight: 5,
          flexShrink: 0
        }}
      >
        <Checkmark width={12} height={12} style={{ color: faded ? colors.p8 : colors.p4 }} />
      </View>
      <Text style={faded && { color: colors.n7 }}>{children}</Text>
    </View>
  );
}

let PricingPage = () => (
  <Layout>
    <Content className="items-center">
      <View
        className="mt-16 items-center"
        style={{
          width: 750,
          [MEDIUM]: { width: 650 },
          [SMALL]: { width: '100%' }
        }}
      >
        <HeaderText className="text-center">
          You{"'"}ll save hundreds of dollars by tracking your spending. See for yourself with a{' '}
          <strong>30-day free trial</strong>.
        </HeaderText>
        <Text className="mt-8 sm:mx-16 text-center text-lg">
          For just <strong>$4/month</strong> you get access to desktop and mobile apps with seamless
          syncing of your data. All we need is your email to start your 30-day free trial, no credit
          card required.
        </Text>

        <SubscribeForm className="mt-6" />
        <Text className="mt-4 text-gray-500 text-sm">
          Available for macOS, Windows, Linux, iOS, and Android (web coming soon)
        </Text>
      </View>

      <HeaderText className="self-start md:self-center mt-40">
        Frequently Asked Questions
      </HeaderText>

      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignSelf: 'center',
            flex: 1
          },
          {
            [MEDIUM]: {
              flexDirection: 'column'
            }
          }
        ]}
      >
        <View style={[{ marginRight: 50 }, { [MEDIUM]: { marginRight: 0 } }]}>
          <QA title="Can I automatically download transactions?">
            Not yet but this is literally the next big feature we are going to launch.
          </QA>
          <QA title="Do you have a web app?">
            There are no plans for a web app right now. We think keeping your data local and syncing
            across devices is not only more robust (it works regardless of your network connection),
            but gives you the ultimate privacy.
          </QA>
          <QA title="Where is my data stored?">
            Your data by default is stored locally on your device. It's always available offline,
            and it can never be analyzed, mined for data, or sold to a third party. If you use
            syncing, we store changes on our servers with 256-bit AES encryption. We are working
            towards end-to-end encryption.
          </QA>
        </View>

        <View>
          <QA title="Why a subscription?">
            A subscription model is the only sustainable approach for an actively developed product.
            Not only does it buy our syncing service (and in the future, downloading transactions), but it funds
            continual active development of the project. We want this to be around for a long time.
          </QA>
          <QA title="Where do I go for support?">
            Please email{' '}
            <Link external to="mailto:help@actualbudget.com">
              help@actualbudget.com
            </Link>{' '}
            for general questions and support!
          </QA>
        </View>
      </View>
    </Content>
  </Layout>
);

export default PricingPage;
