import React from 'react';
import { navigate } from 'gatsby';
import { css } from 'glamor';
import { View, Text, ButtonWithLoading, Link } from './common';
import { SMALL } from './content';
import { colors, gradients } from '../style';
import config from '../config';

class SubscribeForm extends React.Component {
  state = { email: '', sending: false, error: null, formFocused: true };

  onChange = e => {
    this.setState({ email: e.target.value });
  };

  onSubmit = e => {
    let { email, sending } = this.state;
    e.preventDefault();

    if (!email || sending) {
      return;
    }

    this.setState({ sending: true, error: null });

    fetch(config.endpoint + '/subscribe-and-create-stripe', {
      method: 'POST',
      body: JSON.stringify({ email: this.state.email }),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(this.handleResult)
      .catch(err =>
        this.handleResult({
          status: 'error',
          reason: 'network-failure'
        })
      );
  };

  handleResult = res => {
    this.setState({ sending: false });

    let fromapp = !!location.search.match(/fromapp/);

    if (res.status === 'error') {
      this.setState({ error: res.reason });
    } else {
      // Success, forward them to the download page
      navigate('/download/' + (fromapp ? '?fromapp' : ''));
    }
  };

  renderError = error => {
    let msg;

    switch (error) {
      case 'invalid-email':
        msg = 'Invalid email';
        break;
      case 'create-customer':
        msg =
          'Something went wrong creating your subscription. Please contact ' +
          'help@actualbudget.com for support.';
        break;
      case 'exists':
        msg = (
          <Text>
            That email already exists, did you mean to{' '}
            <Link to="/account">sign in</Link>?
          </Text>
        );
        break;
      default:
        msg = 'We hate this, but something went wrong, sorry!';
    }

    return (
      <Text className="mb-4 bg-red-200 text-red-900 p-1 px-3 rounded link-color-inherit">
        {msg}
      </Text>
    );
  };

  render() {
    let { className } = this.props;
    let { email, error, sending } = this.state;

    return (
      <View className={`items-center ${className}`}>
        {error && this.renderError(error)}

        <form
          {...css({ transition: '.5s box-shadow' })}
          className={
            'flex flex-row items-stretch rounded ' +
            (this.state.formFocused ? 'shadow-lg' : '')
          }
          onSubmit={this.onSubmit}
        >
          <input
            placeholder="Email"
            onChange={this.onChange}
            value={email}
            autoFocus
            className="border border-r-0 border-gray-400"
            {...css({
              outline: 'none',
              borderRadius: 4,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderRightWidth: 0,
              padding: '8px 10px',
              width: 350,
              [SMALL]: { width: 200 }
            })}
            onFocus={() => {
              this.setState({ formFocused: true });
            }}
            onBlur={() => {
              this.setState({ formFocused: false });
            }}
          />

          <ButtonWithLoading
            loading={sending}
            style={[
              {
                backgroundColor: '#008280',
                borderColor: 'transparent',
                color: 'white',
                borderWidth: 1,
                borderColor: 'transparent',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
                ':focus': {
                  boxShadow: '0 0px 7px ' + colors.b7,
                  borderColor: colors.b7,
                  outline: 'none'
                }
              }
            ]}
          >
            Sign up
          </ButtonWithLoading>
        </form>
      </View>
    );
  }
}

export default SubscribeForm;
